import { graphql } from "gatsby";
import * as React from "react";
import { HTMLContent } from "../components/common/Content";
import Layout from "../components/layout/Layout";
import Head from "../components/security/Head";
import Reporting from "../components/security/Reporting";
import highlightCode from "../components/utils/HighlightCode";

// props
interface Props {
  data: {
    cve: {
      id: string;
      frontmatter: {
        title: string;
        slug: string;
        severity: string;
        date: string;
        description: string;
      };
      fields: {
        path: string;
      };
    };
  };
}

// markup
const CveTemplate = (props: any) => {
  React.useEffect(() => {
    highlightCode();
  });
  return (
    <Layout className="cve" seo={{ title: props.data.cve.title }}>
      <div className="container">
        <div className="pb-5 cve-content">
          <Head />

          <div className="columns pt-6">
            <div className="column content is-8">
              <h1 className="is-size-3 has-text-weight-semibold">
                {props.data.cve.frontmatter.title}
              </h1>
              <div className="meta py-4">
                {props.data.cve.frontmatter.severity?.toUpperCase()} |{" "}
                {props.data.cve.frontmatter.date.toUpperCase()} |{" "}
                {props.data.cve.frontmatter.slug.toUpperCase()}
              </div>
              <div className="py-3">
                <HTMLContent
                  content={props.data.cve.html}
                  className={"markdown"}
                ></HTMLContent>
              </div>
            </div>
            <div className="column is-4">
              <Reporting />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CveTemplate;

// graphQL CVE query
export const pageQuery = graphql`
  query BlogPage($id: String!) {
    cve: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        slug
        date
        description
        severity
      }
      fields {
        path
      }
    }
  }
`;
